<template>
    <datepicker
        name="date"
        format="yyyy-MM-dd"
        :disabled-dates="disabledDates"
        :inline="false"
        v-model="selectedTemp"
        placeholder="Select Date"
        disabled
        :cleared="() => { this.selectedTemp = null }">
    </datepicker>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
    components: {
        Datepicker,
    },
    props: {
        value: {
            type: Date,
            default: null
        },
        default: {
            type: Boolean,
            default: true
        },
        addDays: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            selected: null,
            disabledDates: {
                to: moment().subtract(1, "days").toDate()
            },
        };
    },
    methods: {
        getDate(added) {
            var now = moment()
            return now.toDate()
        },
        getLocalTime() {
            this.$http.get("/api/v1/master/time-now")
            .then(resp => {
                if (resp.code == 200) {
                    this.selected = moment(resp.data.time).toDate();
                }
            })
            .catch(error => {
                console.log(error)
            });
        }
    },
    watch: {
        selected(v) {
            this.$emit("selected", v);
        },
        value(v) {
            if (v) {
                this.selected = v
            }
        }
    },
    computed: {
        selectedTemp: {
            get() {
                return this.selected;
            },
            set(val) {
                this.selected = val
            },
        }
    },
    mounted() {
        if (this.value) {
            this.selected = this.value
        } else {
            if (this.default) {
                // this.selected = this.getDate(this.addDays)
                this.getLocalTime()
            } else {
                this.selected = null
            }
        }
    }
};
</script> 